<template>
    <div class="action-open-chat-instant">
        <div class="title" v-html="title" />
        <div class="body" v-html="body" />
    </div>
</template>
<script>
export default {
    name: 'ActionOpenChangeSchedule',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        chatUser() {
            return this.chat.user || {}
        },
        title() {
            const contentObj = JSON.parse(this.message.content)
            return contentObj.msg
        },
        body() {
            const reasons = this.$store.getters.chat.schedule_change.reason
            return `약속을 조율하실 수 있도록 대화방을 열어드립니다. </br> </br> 변경 요청 사항은 다음과 같아요. </br> </br> [변경 요청 내용] </br> ${reasons} </br> </br> 일정 및 장소 관련하여 이야기 나누시고 채팅방 하단에 있는 버튼을 통해 완료된 일정을 입력해주세요. `
        },
    },
}
</script>
